<div class="panel-container">
    <div id="left-container" class="left-container" #leftContainer>
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'IO_SERVERS' | translate }}">IO_SERVERS</h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button type="button" class="btn btn-outline-primary me-2" routerLink="/{{ urls.io_servers }}/new" title="{{ 'ADD' | translate }}">
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel>
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'tunnel_server'"
                    [displayTableName]="'IO_SERVERS' | translate"
                    [data]="ioServers$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [showReport]="false"
                    [isColumnSelectionNeeded]="true"
                    [showSelectionCheckbox]="false"
                    [selectable]="false"
                    [hoverable]="false"
                >
                </app-table-list>
            </div>
        </div>
    </div>
</div>

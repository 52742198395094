<content-loading-animation *ngIf="loading || routeLoading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" *ngIf="!loading && !routeLoading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_IO_SERVER" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_IO_SERVER" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <!-- DNS Prefix -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="dns_prefix" [ngClass]="{ 'is-invalid': form.submitted && dns_prefix.errors }"
                            >{{ "DNS_PREFIX" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="dns_prefix"
                            name="dns_prefix"
                            placeholder="{{ 'DNS_PREFIX' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && dns_prefix.errors }"
                            [(ngModel)]="ioServer.dns_prefix"
                            required
                            pattern="^[a-z0-9]+$"
                            duplicateName="{{ blacklist }}"
                            #dns_prefix="ngModel"
                        />
                        <div *ngIf="dns_prefix.invalid" class="invalid-feedback">
                            <div *ngIf="dns_prefix.errors.required">{{ "DNS_PREFIX" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="dns_prefix.errors.duplicateName">{{ "DNS_PREFIX" | translate }} {{ "CANNOT_BE_EQUAL_TO_ZIXI" | translate }}.</div>
                            <div *ngIf="dns_prefix.errors.pattern">{{ "DNS_PREFIX" | translate }} {{ "PH_DOMAINS" | translate }}.</div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isEdit">
                        <label for="dns_prefix">{{ "DNS_PREFIX" | translate }}</label>
                        <input type="text" readonly class="form-control-plaintext" id="dns_prefix" name="dns_prefix" value="{{ ioServer.dns_prefix }}" />
                    </div>
                    <!-- Account -->
                    <div class="form-group">
                        <label for="account_select" [ngClass]="{ 'is-invalid': form.submitted && form.controls.account_select?.errors }"
                            >{{ "ACCOUNT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <zx-customer-select
                            name="account_select"
                            [(model)]="customer_id"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.account_select?.errors }"
                            required
                        ></zx-customer-select>
                        <div *ngIf="form.controls.account_select?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.account_select?.errors.required">{{ "ACCOUNT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Instance Type -->
                    <div class="form-group">
                        <label for="instance_type" [ngClass]="{ 'is-invalid': form.submitted && instance_type.errors }">
                            {{ "INSTANCE_TYPE" | translate }}
                        </label>
                        <ng-select
                            id="instance_type"
                            name="instance_type"
                            required
                            [items]="instanceTypes"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'INSTANCE_TYPE' | translate }}"
                            [(ngModel)]="ioServer.instance_type"
                            #instance_type="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && instance_type.errors }"
                        >
                        </ng-select>
                        <div *ngIf="instance_type.invalid" class="invalid-feedback">
                            <div *ngIf="instance_type.errors.required">{{ "INSTANCE_TYPE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Region -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                            {{ "REGION" | translate }}
                        </label>
                        <ng-select
                            id="region"
                            name="region"
                            required
                            [items]="regions"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            (change)="regionChange()"
                            placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                            [(ngModel)]="ioServer.region"
                            [loading]="optionsLoading"
                            #region="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                        >
                        </ng-select>
                        <div *ngIf="region.invalid" class="invalid-feedback">
                            <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Version -->
                    <div class="form-group">
                        <label for="version" [ngClass]="{ 'is-invalid': form.submitted && version.errors }">
                            {{ "VERSION" | translate }}
                        </label>
                        <ng-select
                            id="version"
                            name="version"
                            required
                            [items]="amis[ioServer.region]"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            placeholder="{{ 'SELECT' | translate }} {{ 'VERSION' | translate }}"
                            [(ngModel)]="ioServer.ami"
                            [loading]="optionsLoading"
                            #version="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && version.errors }"
                        >
                        </ng-select>
                        <div *ngIf="version.invalid" class="invalid-feedback">
                            <div *ngIf="version.errors.required">{{ "VERSION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Restart existing tunnel server -->
                    <div class="form-group" *ngIf="isEdit">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="restart" name="restart" [(ngModel)]="restart" />
                            <label class="form-check-label" for="restart">{{ "RESTART_EXISTING_TUNNEL_SERVER" | translate }} </label>
                        </div>
                    </div>
                    <app-error [marginBottom]="true" [marginTop]="true"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { IoServersComponent } from "./io-servers.component";
import { IoServerFormComponent } from "./io-server-form/io-server-form.component";
import { IoServerListComponent } from "./io-server-list/io-server-list.component";
import { IoServersRouting } from "./io-servers.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, IoServersRouting],
    declarations: [IoServersComponent, IoServerFormComponent, IoServerListComponent]
})
export class IoServersModule {}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { IoServerFormComponent } from "./io-server-form/io-server-form.component";
import { IoServersComponent } from "./io-servers.component";
import { IoServerListComponent } from "./io-server-list/io-server-list.component";
import { IoServerListResolverService } from "./io-server-list/io-server-list-resolver.service";

const ioServerRoutes: Routes = [
    {
        path: Constants.urls.io_servers + "/new",
        component: IoServerFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.io_servers + "/:id/:action",
        component: IoServerFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.io_servers,
        component: IoServersComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: "",
                component: IoServerListComponent,
                resolve: {
                    ioServer: IoServerListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(ioServerRoutes)],
    exports: [RouterModule]
})
export class IoServersRouting {}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "../../../services/shared.service";
import { IoServersService, IoServer } from "../../../components/io-servers/io-servers.service";

@Component({
    selector: "app-io-server-form",
    templateUrl: "./io-server-form.component.html"
})
export class IoServerFormComponent implements OnInit {
    ioServer: IoServer;
    existingIoServer: IoServer;
    tunnelID: number;
    blacklist: string[] = ["zixi"];
    action: string;

    loading = true;
    routeLoading = true;
    saving = false;
    submitted = false;
    isEdit = false;
    optionsLoading: boolean;

    regions: string[] = [];
    instanceTypes = ["t4g.medium", "t4g.xlarge", "t4g.2xlarge"];
    ami: string;
    customer_id: number | undefined;
    amis: Record<string, { value: string; name: string }[]>;
    restart: boolean;
    constants = Constants;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        private ios: IoServersService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.tunnelID = parseInt(params.get("id"), 10);
            this.action = params.get("action");

            if (this.tunnelID) {
                this.ioServer = Object.assign({}, this.ios.getCachedIoServer(this.tunnelID));
                this.existingIoServer = _.cloneDeep(this.ioServer);
                if (this.sharedService.isEmptyObject(this.ioServer)) {
                    this.ios
                        .refreshIoServers(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.ioServer = Object.assign({}, this.ios.getCachedIoServer(this.tunnelID));
                            this.existingIoServer = _.cloneDeep(this.ioServer);
                            this.prepForm();
                            this.routeLoading = false;
                        });
                } else {
                    this.routeLoading = false;
                }
            } else {
                this.routeLoading = false;
            }
        });
    }

    async ngOnInit() {
        if (this.action === "edit") this.isEdit = true;
        await this.getOptions();
        this.prepForm();
        this.loading = false;
    }

    async getOptions() {
        this.optionsLoading = true;
        const result = await this.ios.options();
        if (result) {
            const now = Date.now();
            this.amis = _.mapValues(result, amis => {
                const sortedAmis = _.sortBy(amis, ami => {
                    return now - new Date(ami.created_at).valueOf();
                });
                return sortedAmis.map(ami => ({ value: ami.ami_id, name: ami.description }));
            });
            this.regions = Object.keys(this.amis);
        } else {
            this.amis = {};
            this.regions = [];
        }

        this.optionsLoading = false;
    }

    regionChange() {
        this.ioServer.ami = this.amis?.[this.ioServer.region]?.[0]?.value;
    }

    prepForm() {
        if (!this.ioServer && !this.isEdit) {
            this.ioServer = new IoServer();
            this.ioServer.region = "us-east-1";
            this.ioServer.instance_type = "t4g.medium";
            this.ioServer.ami = this.amis?.[this.ioServer.region]?.[0]?.value;
        } else if (this.isEdit) {
            if (!this.ioServer.region) this.ioServer.region = "us-east-1";
            this.customer_id = this.ioServer.Customer?.id;
        }
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            dns_prefix: this.isEdit ? undefined : this.ioServer.dns_prefix,
            instance_type: this.ioServer.instance_type,
            ami: this.ioServer.ami,
            region: this.isEdit ? undefined : this.ioServer.region,
            restart: this.restart,
            customer_id: this.isEdit ? undefined : this.customer_id
        };

        if (this.isEdit) {
            const result = await this.ios.updateIoServer(this.ioServer, model);
            if (result) this.router.navigate([Constants.urls.io_servers]);
            else this.saving = false;
        } else {
            const result = await this.ios.addIoServer(model);
            if (result) this.router.navigate([Constants.urls.io_servers]);
            else this.saving = false;
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.io_servers]);
    }
}

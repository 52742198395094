import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { IoServer, IoServersService } from "../../../components/io-servers/io-servers.service";

@Injectable({
    providedIn: "root"
})
export class IoServerListResolverService implements Resolve<IoServer[]> {
    constructor(private ios: IoServersService) {}

    resolve(): Observable<IoServer[]> | Observable<never> {
        return Observable.create((observe: Subscriber<IoServer[]>) => {
            this.ios.refreshIoServers().subscribe((ioServers: IoServer[]) => {
                observe.next(ioServers);
                observe.complete();
            });
        });
    }
}
